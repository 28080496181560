<template>
  <v-card flat class="pa-5">
    <div
      v-for="(item, key) in data"
      :key="key"
      class="full-width d-flex rounded-lg mb-6"
      style="height: 120px; box-shadow: 0px 0px 24px #00000014;">
        <div
          class="d-flex align-center justify-center font-32 rounded-l-lg full-width"
          :style="'max-width: 308px; height: 100%; background: ' + item.background">
            <span class="font-bold" :style="item.id === 1 ? 'color: #000000' : 'color: #FFFFFF'">{{ item.title }}</span>
        </div>
        <div class="d-flex align-center justify-center full-width" style="max-width: 200px">
            <v-icon small color="#CDD2DA" style="font-size: 29.33px" >mdi-account-multiple</v-icon>
            <span class="ml-2">{{ item.unlimited_staff ? 'Unlimited' : item.staff }} Staff</span>
        </div>
        <div
          class="py-4 full-width"
          :style="item.prices.length > 3 ? 'overflow-y: auto' : 'overflow-y: unset'">
            <div
              v-for="(price, key) in item.prices"
              :key="key + 'A'"
              class="rounded-lg d-flex aling-center px-2 mb-2" style="max-width: 304px; border: 1px solid #BEC2C4;">
                <div class="font-12 mr-3" style="color: #767676;margin-top: 2px">{{ price.currency_id }}</div>
                <div class="full-width font-14 font-bold">M: {{ price.fprice_monthly }}</div>
                <div class="full-width font-14 font-bold">Y: {{ price.fprice_annually }}</div>
            </div>
        </div>
        <div class="d-flex align-center justify-center full-width">
            <v-btn rounded depressed outlined color="#00D79E" class="text-capitalize" @click="handleEdit(item)">Manage</v-btn>
        </div>
    </div>

    <v-dialog v-model="showDialog" max-width="50%">
        <v-card>
            <v-toolbar color="elevation-0" class="d-flex justify-center align-center" :style="'height: 144px; background:' + tempEdit.background">
                <v-toolbar-title >
                    <span class="ml-4 font-weight-bold font-32" :style="tempEdit.id === 1 ? 'color: #000000' : 'color: #FFFFFF'">{{ tempEdit.title }}</span>
                </v-toolbar-title>
                <v-toolbar-items style="position: fixed; right: 10px; top: 10px;">
                    <v-btn icon>
                        <v-icon :color="tempEdit.id === 1 ? '#000000' : '#FFFFFF'">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <div class="px-10 mt-8 pb-4">
                <div class="full-width pl-2">
                    <span class="font-14">Staff</span>
                    <v-text-field v-model="tempEdit.staff" outlined dense height="40px" style="min-height: unset !important"></v-text-field>
                </div>
                <div
                  v-for="(item, key) in tempEdit.prices"
                  :key="key + 'a'"
                  class="px-6 py-2 mb-3"
                  style="box-shadow: 0px 0px 24px #00000014; border-radius: 8px;">
                    <div class="d-flex pt-2">
                        <div class="full-width">{{ item.currency_id}}</div>
                        <div class="mt-n2">
                            <v-btn icon @click="handleShow(idx)">
                                <v-icon >{{ tempCurr[idx] ? 'mdi-chevron-down' : 'mdi-chevron-right'}}</v-icon>
                            </v-btn>
                        </div>
                    </div>
                    <div v-show="tempCurr[idx]">
                        <hr class="mt-4" style="border: 1px solid #E0E0E0">
                        <div class="d-flex mt-4">
                            <div class="full-width pr-2">
                                <span class="font-14">Monthly Price</span>
                                <v-text-field v-model="item.price_monthly" outlined dense height="40px" style="min-height: unset !important"></v-text-field>
                            </div>
                            <div class="full-width pl-2">
                                <span class="font-14">Yearly Price</span>
                                <v-text-field v-model="item.price_annually" outlined dense height="40px" style="min-height: unset !important"></v-text-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex pt-4 px-4" style="box-shadow: 0px -5px 5px #F5F5F5; height: 80px">
                <div class="text-right full-width">
                    <v-btn class="text-capitalize" color="#00D79E" depressed style="color: #FFFFFF" @click="showConfirm = true, showDialog = false">Save Setting</v-btn>
                </div>
            </div>
        </v-card>
    </v-dialog>

    <v-dialog v-model="showConfirm" max-width="50%">
        <v-card>
            <v-toolbar color="elevation-0">
                <v-toolbar-title >
                <span class="ml-4 font-weight-bold">Change Confirmation</span>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                <v-btn icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <div class="pa-8">
                You are about to change package setting. All change will be applied to a whole system. Are you sure want to change?
            </div>
            <div class="d-flex pt-4 px-4" style="height: 80px">
                <div class="text-right full-width">
                    <v-btn class="text-capitalize mr-2" outlined depressed @click="showConfirm = false, showDialog = true" style="border-color: #E0E0E0">Back to Setting</v-btn>
                    <v-btn class="text-capitalize" color="#00D79E" depressed style="color: #FFFFFF" @click="updatePackage">Yes</v-btn>
                </div>
            </div>
        </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { fetchPackageList, updatePackageList } from '@/api/zenwelbiz'
export default {
  name: 'Package',

  data () {
    return {
      data: [],
      showDialog: false,
      showConfirm: false,
      tempEdit: {
        id: 0
      },
      tempCurr: [false]
    }
  },

  mounted () {
    this.getPackage()
  },

  methods: {
    getPackage () {
      fetchPackageList().then((response) => {
        this.data = response.data.data
        this.data.map(i => {
          if (i.id === 1) {
            i.background = 'transparent linear-gradient(180deg, #E0E0E0 0%, #EEEEEE 100%) 0% 0% no-repeat padding-box;'
          }
          if (i.id === 2) {
            i.background = 'transparent linear-gradient(180deg, #46E8BD 0%, #0BD0D4 100%) 0% 0% no-repeat padding-box;'
          }
          if (i.id === 3) {
            i.background = 'transparent linear-gradient(180deg, #2882F5 0%, #0BD0D4 100%) 0% 0% no-repeat padding-box;'
          }
          if (i.id === 4) {
            i.background = 'transparent linear-gradient(180deg, #7E11A1 0%, #BC4DAA 100%) 0% 0% no-repeat padding-box;'
          }
        })
      }).catch((error) => {
        console.log('rsp', error)
      })
    },

    updatePackage () {
      console.log('log')
      updatePackageList(this.tempEdit).then((response) => {
        this.showDialog = false
        this.showConfirm = false
        this.getPackage()
        this.tempEdit = {}
      }).catch((error) => {
        console.log('rsp', error)
      })
    },

    handleEdit (data) {
      this.tempEdit = data
      this.tempEdit.prices.map(i => {
        this.tempCurr.push(false)
        i.price_annually = parseInt(i.price_annually)
        i.price_monthly = parseInt(i.price_monthly)
      })
      this.showDialog = true
    },

    handleShow (idx) {
      this.$set(this.tempCurr, idx, !this.tempCurr[idx])
    }
  }
}
</script>
