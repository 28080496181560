import request from '@/utils/request'
// import store from '@/store'
import Vuetify from '@/plugins/vuetify'
var langId = Vuetify.preset.lang.current

export function fetchBussinessList (params) {
  return request({
    url: langId + '/business',
    method: 'get',
    params: params
  })
}

export function detailBussiness (id) {
  return request({
    url: langId + '/business/' + id,
    method: 'get'
  })
}

export function fetchSubscriptionList () {
  return request({
    url: langId + '/subscription/package',
    method: 'get',
    params: {
      currency: 'IDR'
    }
  })
}

export function fetchPackageList () {
  return request({
    url: langId + '/subscriptionList',
    method: 'get'
  })
}

export function updatePackageList (data) {
  return request({
    url: langId + '/subscriptionList/update/' + data.id,
    method: 'post',
    data
  })
}

export function fetchCurrencyList () {
  return request({
    url: langId + '/currency',
    method: 'get'
  })
}

export function fetchCurrencyDetail (id) {
  return request({
    url: langId + '/package/currency/' + id,
    method: 'get'
  })
}

export function updateCurrencyDetail (data) {
  let prices = { prices: data.prices }
  return request({
    url: langId + '/package/currency/update/' + data.id,
    method: 'post',
    data: prices
  })
}
